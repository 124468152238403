<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <div class="flex">
        <button
          class="p-2 rounded-md text-xs text-black"
          @click="
            $router.push({
              name: 'Permissions',
            })
          "
        >
          < Back
        </button>
      </div>
      <h1 class="text-2xl my-5 font-bold text-blue-800">Users</h1>

      <div class="flex flex-row mt-5">
        <div class="float-right">
          <input
            type="text"
            class="inline-block mx-2 px-2 ring-2 outline-none ring-blue-500 rounded-sm ml-2 w-64 text-sm py-3"
            name=""
            placeholder="Search User"
            id=""
            v-model="search"
          />
        </div>
        <button
          class="bg-blue-800 uppercase py-2 px-6 rounded-md text-white text-sm"
          @click="searchRecords"
        >
          Search
        </button>
        <button
          class="bg-red-600 uppercase py-2 px-6 rounded-md text-white text-sm"
          @click="resetSearchRecords"
        >
          Reset
        </button>
      </div>
      <div class="flex flex-row my-3 p-2">
        <div class="w-1/4 block mr-3">
          <multiselect
            :taggable="false"
            v-model="filterByDept"
            :options="departments"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="false"
            placeholder="Filter by Department"
          >
            <template slot="selection" slot-scope="{ values, search, isOpen }"
              ><span
                class="multiselect__single hidden"
                v-if="values.length &amp;&amp; !isOpen"
                >{{ values.toString() }}</span
              ></template
            >
          </multiselect>
        </div>
        <div class="block">
          <button
            class="bg-blue-800 uppercase py-3 px-6 rounded-md text-white text-sm"
            @click="filterByDepartment"
          >
            Apply
          </button>
        </div>
        <div class="block ml-2">
          <button
            class="bg-red-600 uppercase py-3 px-6 rounded-md text-white text-sm"
            @click="resetDepartmentRecords"
          >
            Reset
          </button>
        </div>
      </div>
      <div class="flex flex-row mt-5" style="max-height: 500px; overflow: auto">
        <table class="shadow-lg bg-white w-full">
          <thead>
            <th
              class="text-l p-3 text-left"
              colspan="17"
              style="background: #dee6f2"
            >
              Users Information
            </th>
            <tr style="background: #fff">
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Users ID
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                First Name
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Last Name
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Email
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Department
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="cursor-pointer"
              v-for="(user, i) in filterUsers"
              :key="i"
            >
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ user._id || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ user.first_name || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ user.last_name || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ user.email || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ user.department || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                <button
                  @click="
                    $router.push({
                      name: 'PermissionsUserById',
                      params: {
                        id: user._id,
                        name: user.first_name,
                      },
                    })
                  "
                  class="bg-blue-900 p-3 text-white rounded"
                >
                  View
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-3">
        <button
          @click="prevPage(currentPage)"
          :disabled="currentPage === 1 ? isDisabled : !isDisabled"
          class="uppercase shadow ml-3 focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-8 rounded"
          :class="
            currentPage === 1
              ? 'bg-blue-50 text-black hover:bg-blue-600 hover:text-white'
              : 'bg-blue-900 text-white hover:bg-blue-600'
          "
        >
          &laquo; Prev
        </button>

        <button
          @click="nextPage(currentPage)"
          class="float-right uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
          v-if="filterUsers.length > 99"
        >
          Next &raquo;
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import moment from "moment";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      isDisabled: true,
      currentPage: 1,
      search: "",
      filterByDept: "",
      filterByDeptFlag: false,
      filterBySearchFlag: false,
      departments: [
        "Commercial Operations",
        "Settlement and Reconciliation Team (Settlement)",
        "KYC Compliance/Fraud Desk (Fraud Management)",
        "Terminal Management/Platform Monitoring Team (Terminal Management)",
        "Chargeback Team",
        "Product Operations",
        "Operations",
        "Marketing",
        "Credit Operations",
      ],
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    ...mapGetters(["GET_ALL_ADMINS"]),
    filterUsers() {
      return this.GET_ALL_ADMINS.data && this.GET_ALL_ADMINS.data.users;
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async created() {
    this.fetchRecords();
  },
  methods: {
    async fetchRecords() {
      this.isLoading = true;
      this.currentPage = 1;
      const payload = {
        page: this.currentPage,
        filterBySearch: this.filterBySearchFlag,
        filterByDept: this.filterByDeptFlag,
        department: this.filterByDept,
        search: this.search,
      };
      try {
        await this.$store.dispatch("FETCH_ALL_ADMINS", payload);
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async searchRecords() {
      this.isLoading = true;
      this.filterBySearchFlag = true;
      this.currentPage = 1;
      const payload = {
        page: this.currentPage,
        filterBySearch: this.filterBySearchFlag,
        search: this.search,
        filterByDept: this.filterByDeptFlag,
        department: this.filterByDept,
      };
      try {
        await this.$store.dispatch("FETCH_ALL_ADMINS", payload);
        this.isLoading = false;
      } catch (error) {}
    },
    async filterByDepartment() {
      this.isLoading = true;
      this.filterByDeptFlag = true;
      this.currentPage = 1;
      const payload = {
        page: this.currentPage,
        filterByDept: this.filterByDeptFlag,
        department: this.filterByDept,
        filterBySearch: this.filterBySearchFlag,
        search: this.search,
      };
      try {
        await this.$store.dispatch("FETCH_ALL_ADMINS", payload);
        this.isLoading = false;
      } catch (error) {}
    },
    async resetSearchRecords() {
      this.filterBySearchFlag = false;
      this.search = "";
      this.fetchRecords();
    },
    async resetDepartmentRecords() {
      this.filterByDeptFlag = false;
      this.filterByDept = "";
      this.fetchRecords();
    },
    async nextPage() {
      this.isLoading = true;
      let currPage = this.currentPage;

      const payload = {
        page: currPage + 1,
        filterBySearch: this.filterBySearchFlag,
        filterByDept: this.filterByDeptFlag,
        department: this.filterByDept,
        search: this.search,
      };
      try {
        const res = await this.$store.dispatch("FETCH_ALL_ADMINS", payload);
        this.isLoading = false;
        if (res.status) {
          this.currentPage += 1;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async prevPage() {
      this.isLoading = true;
      let currPage = this.currentPage;

      const payload = {
        page: currPage - 1,
        filterBySearch: this.filterBySearchFlag,
        filterByDept: this.filterByDeptFlag,
        department: this.filterByDept,
        search: this.search,
      };
      try {
        const res = await this.$store.dispatch("FETCH_ALL_ADMINS", payload);
        this.isLoading = false;
        if (res.status) {
          this.currentPage -= 1;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(241, 241, 241);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
